.python-courses {
    padding-top: 114px;
    position: relative;

    .courses-wrapper {
        width: 738px;
        margin: auto;
        margin-top: 64px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .courses {
            display: flex;
            justify-content: center;
        }

        .upcoming {
            margin-top: 56px;
            margin-bottom: 24px;
            text-align: center;
            color: #606266;
            letter-spacing: 0.05em;
            font-size: 20px;
            line-height: 150%;
        }

        .upcoming-courses {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 -12px;
            img {
                margin: 0 12px;
                box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.15);
            }
        }

        .full-plan-btn {
            margin-top: 80px;
            margin-bottom: 112px;
        }
    }
}
