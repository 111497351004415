.page-register {
    background: #f2f2f2;
    .main {
        max-width: 750px;
        margin: 0 auto;
        background: #fff;
        position: relative;
        @media screen and (min-width: 750px) {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }
        .register-button {
            position: fixed;
            right: 0px;
            top: 50%;
            margin-top: -54px;
            cursor: pointer;
            img {
                width: 39px;
                height: 108px;
            }
        }
        .section1 {
            position: relative;
            overflow: hidden;
            text-align: center;
            color: #fff;
            .background {
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
            .content {
                position: relative;
                height: 100vh;
                display: flex;
                flex-direction: column;
                .logo {
                    text-align: left;
                    border-bottom: 1px solid #ffffff66;
                    img {
                        width: 180px;
                        height: 36px;
                        margin: 22px 26px;
                    }
                }
                .space {
                    flex: 1;
                }
                a.go-home {
                    color: var(--color-boyu-blue);
                    padding-bottom: 4px;
                    border-bottom: 1px solid var(--color-boyu-blue);
                }
            }
            h1 {
                margin-top: 20px;
                color: #fff;
                font-size: 54px;
                font-weight: bold;
                opacity: 0.8;
                margin-bottom: 10px;
            }
            h2 {
                color: #fff;
                font-size: 32px;
                opacity: 0.7;
                margin: 0;
                font-weight: normal;
            }
            @media screen and (max-width: 500px) {
                .content {
                    .logo {
                        img {
                            width: 135px;
                            height: 27px;
                            margin: 15px 20px;
                        }
                    }
                }
            }
        }
        .section2 {
            background: #EAF2F9;
            padding-bottom: 100px;
            h2 {
                text-align: center;
                padding: 50px 0 50px 0;
                font-weight: 200;
                span {
                    font-weight: 400;
                    color: var(--color-boyu-blue);
                }
            }
        }
        .section3 {
            padding-top: 50px;
            padding-bottom: 50px;
            position: relative;
            overflow: hidden;
            background: #37B8F9;
            .background {
                width: calc(100% + 10px);
                height: auto;
                position: absolute;
                top: 0;
                left: -10px;
            }
            .content {
                position: relative;
            }
            h2 {
                opacity: 1 !important;
                font-weight: 200;
                color: #fff;
                text-align: center;
                span {
                    font-weight: 400;
                }
            }
            @media screen and (max-width: 500px) {
            }
            @media screen and (max-width: 350px) {
            }
        }
        .section3-1 {
            padding-top: 50px;
            padding-bottom: 50px;
            position: relative;
            overflow: hidden;
            background: #EBF6FE;
            .content {
                position: relative;
            }
            h2 {
                opacity: 1 !important;
                font-weight: 200;
                text-align: center;
                color: black;
                span {
                    font-weight: 400;
                    color: var(--color-boyu-blue);
                }
            }
        }
        .section4 {
            background: #2F2E33;
            padding: 50px;
            h2 {
                color: #fff;
                text-align: center;
                margin-bottom: 40px;
            }
            .comment {
                color: #fff;
                margin-bottom: 40px;
                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                p {
                    margin-top: 10px;
                    opacity: 0.7;
                }
            }
        }
        .section5 {
            background: #EAF2F9;
            padding: 100px;
            h2 {
                opacity: 1 !important;
                &.drop {
                    color: var(--color-boyu-blue);
                    font-weight: 900;
                    margin-top: -0.5em;
                }
            }
            .drop-box {
                display: flex;
                border: 2px solid var(--color-boyu-blue);
                width: 160px;
                border-radius: 10px;
                overflow: hidden;
                align-items: center;
                background: #fff;
                margin-top: 40px;
                .left {
                    background: var(--color-boyu-blue);
                    img {
                        width: 21px;
                        height: 32px;
                        margin: 18px;
                    }
                }
                .right {
                    font-size: 28px;
                    font-weight: bolder;
                    text-align: center;
                    flex: 1;
                }
            }
            .drop-desc {
                margin-top: 20px;
                font-size: 12px;
            }
            @media screen and (max-width: 500px) {
                padding: 40px;
                .drop-box {
                    width: 100px;
                    margin-top: 20px;
                    .left {
                        img {
                            width: 14px;
                            height: 21px;
                            margin: 12px;
                        }
                    }
                    .right {
                        font-size: 16px;
                    }
                }
            }
        }
        .section6 {
            background: #EAF2F9;
            padding-bottom: 140px;
            padding-top: 80px;
            position: relative;
            .background {
                width: 100%;
                height: auto;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .login-form-wrapper {
                background: #fff;
                border-radius: 40px;
                max-width: 520px;
                padding: 88px 60px 0;
                margin: 0 auto;
                position: relative;
                .logo {
                    position: absolute;
                    left: 50%;
                    margin-left: -70px;
                    top: -70px;
                    width: 140px;
                    height: 140px;
                }
                .site-title {
                    text-align: center;
                }
                .login-form {
                    padding: 40px 20px;
                    max-width: 400px;
                    margin: 0 auto;
                    .user-phone {
                        border: 1px solid #2FB1F8;
                        border-radius: 5px;
                        padding: 5px;
                        text-align: center;
                        margin-bottom: 30px;
                    }
                    .logout-button {
                        width: 100%;
                        background: var(--color-boyu-blue);
                        border-color: var(--color-boyu-blue);
                    }
                }
            }
            @media screen and (max-width: 500px) {
                padding-bottom: 60px;
                .background {
                    display: none;
                }
                .login-form-wrapper {
                    padding: 88px 10px 0;
                    margin: 0 10px;
                    .logo {
                        margin-left: -50px;
                        top: -50px;
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
        .section {
            h1 {
                font-size: 54px;
                font-weight: bold;
                opacity: 0.8;
            }
            h2 {
                font-size: 32px;
                opacity: 0.7;
            }
            .continue {
                margin: 50px 0;
                cursor: pointer;
                text-align: center;
                img {
                    width: 44px;
                    height: 44px;
                }
            }
            .BrainhubCarouselItem {
                transform: scale(0.7);
                > div {
                    margin-left: -40px;
                    margin-right: -40px;
                    position: relative;
                    padding: 0 10px;
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                    }
                    img.shadow {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: none;
                    }
                }
                &--active {
                    transform: unset;
                    margin-left: unset;
                    > div {
                        margin-left: 0;
                        margin-right: 0;
                        img.shadow {
                            display: block;
                        }
                    }
                }
            }
            @media screen and (max-width: 500px) {
                h1 {
                    font-size: 36px;
                }
                h2 {
                    font-size: 20px;
                }
            }
            @media screen and (max-width: 350px) {
                h1 {
                    font-size: 24px;
                }
                h2 {
                    font-size: 16px;
                }
            }
        }
    }
}
.page-register-result {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .result {
        margin-bottom: 40px;
        margin-top: -100px;
    }
    .use-mobile {
        color: #90c9ff;
        text-decoration: underline;
        margin-top: 20px;
        font-size: 10px;
    }
    .go-home {
        color: #90c9ff;
        text-decoration: underline;
        margin-top: 20px;
        font-size: 14px;
        a {
            color: #90c9ff;
        }
    }
    .text {
        font-weight: bolder;
        &.small {
            font-weight: normal;
            font-size: 10px;
        }
    }
}