.ui-activity-guide {
    img {
        animation: enter-arrow-animate infinite 1s linear;
    }
    @keyframes enter-arrow-animate {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(4px, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}
