.ui-activity-loading {
    position: relative;
    left: calc((890px - 200px) / 2 - 30px);
    top: 220px;
    width: 200px;
    .loading {
        z-index: 201;
        width: 160px;
        position: absolute;
        top: 50px;
        right: 8px;
    }
    .sakura {
        z-index: 200;
        width: 150px;
        height: auto;
        position: absolute;
        top: 6px;
        left: -24px;
        transform-origin: 76px 70px;
        animation: rotate 8s linear infinite;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}
