.comment-table-item {
    border: gray 1px dashed;
    border-radius: 10px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .comment-table-item-index {
        width: 30px;
        margin-left: 10px;
    }
    .comment-table-item-left {
        padding: 20px 20px 20px 5px;
        width: 150px;
        font-size: 24px;
        text-align: center;
        .comment-table-item-type {
            font-size: 24px;
        }
        .unanswered {
            font-size: 12px;
            color: red;
        }
    }
    .comment-table-item-link {
        font-size: 14px;
    }
    .comment-table-item-detail {
        border-left: gray 1px dashed;
        border-right: gray 1px dashed;
        width: 100%;
        overflow: hidden;
        .comment-table-item-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            width: 100%;
            font-size: 18px;
            border-bottom: gray 1px dashed;
            .comment-table-item-info {
                display: flex;
                flex-direction: row;
                span {
                    margin: 0 8px;
                }
            }
            .comment-table-item-time {
                float: right;
            }
        }
        .comment-table-item-content {
            img {
                max-width: 100%;
            }
            padding: 20px;
            margin-bottom: 40px;
            min-height: 50px;
        }
    }
    .comment-table-item-operation {
        text-align: center;
        button {
            margin: 10px;
        }
    }
}
