.home-v2 {
    padding-top: 65px;
    .home-carousel-box {
        width: 100%;
        .carousel-item {
            background: #fff;
            margin-bottom: -5px;
            position: relative;
            max-height: 540px;
            img.bg {
                width: 100%;
                height: auto;
            }
            .main {
                position: absolute;
                width: 960px;
                height: 470px;
                bottom: 0;
                left: 50%;
                margin-left: -480px;
                &-content {
                    position: relative;
                    width: 100%;
                    height: 470px;
                    display: flow-root; // prevent margin collapse
                    .enter, .enter-gift {
                        width: 188px;
                        height: 48px;
                        border-radius: 24px;
                        background: #fff;
                        box-shadow: 0px 11px 22px rgba(145, 177, 255, 0.2);
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .circle {
                            width: 36px;
                            height: 36px;
                            background: #0D7DFC;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 6px;
                            border-radius: 50%;
                            font-size: 12px;
                        }
                        .text {
                            text-align: center;
                            flex: 1;
                            margin-right: 20px;
                            color: rgba(0,0,0,.65);
                            &.space {
                                letter-spacing: 0.05em;
                            }
                        }
                        &:hover {
                            .circle {
                                img {
                                    animation: enter-arrow-animate 1 0.6s linear;
                                }
                            }
                        }
                        &.transparent {
                            background: rgba(255, 255, 255, .2);
                            .text {
                                color: #fff;
                            }
                        }
                    }
                    .enter-gift {
                        background: #E23E49;
                        box-shadow: 0px 22px 44px rgba(51, 51, 51, 0.2);
                        color: white;
                        .circle {
                            background: linear-gradient(180deg, #FFFEBE 0%, #FBC975 100%);
                        }
                    }
                    .control {
                        position: absolute;
                        cursor: pointer;
                        img:nth-child(2) {
                            display: none;
                        }
                        &:hover {
                            img:nth-child(1) {
                                display: none;
                            }
                            img:nth-child(2) {
                                display: inline;
                            }
                        }
                        &.left {
                            left: -60px;
                        }
                        &.right {
                            right: -60px;
                            transform: rotate(180deg);
                        }
                    }

                    // Section 量子位banner相关样式
                    .liangzi-title {
                        width: 452px;
                        margin-top: 40px;
                    }
                    .liangzi-slogan {
                        background: linear-gradient(
                            90deg,
                            #e44ba7 -7.82%,
                            #4449bf 44.71%,
                            #3a72c6 91.06%
                        );
                        border-radius: 50px;
                        color: #ffffff;
                        text-align: center;
                        font-weight: 500;
                        font-size: 18px;
                        display: inline-block;
                        padding: 10px 20px;
                        margin-top: 40px;
                    }
                    .liangzi-courses {
                        position: absolute;
                        right: 0;
                        top: 40px;
                        img {
                            height: 22px;
                            &:nth-of-type(2) {
                                margin-top: 20px;
                            }
                        }
                    }
                    .liang-zi-wei {
                        display: flex;
                        margin-top: 10px;
                        align-items: center;
                        .desc {
                            letter-spacing: 0.1em;
                            border-radius: 11px;
                            color: #ffffff;
                            font-weight: 500;
                            padding: 10px 20px;
                            font-size: 14px;
                            background: linear-gradient(
                                -90deg,
                                rgba(215, 90, 113, 0) 17.11%,
                                rgba(228, 75, 167, 0.5) 72.6%,
                                rgba(58, 88, 198, 0.5) 91.05%
                            );
                            margin-right: 10px;
                            width: 290px;
                        }
                        .circle {
                            background: linear-gradient(
                                90.49deg,
                                #d75a71 -7.82%,
                                #5f3ac6 91.06%
                            );
                            .star {
                                width: 36px;
                                height: 36px;
                            }
                        }
                        .text {
                            color: #5f3ac6;
                            display: relative;
                            background: linear-gradient(
                                92.02deg,
                                #d75a71 -7.82%,
                                #5f3ac6 91.06%
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        &.coming-soon{
                            a {
                                background: rgba(255, 253, 254, 0.1);
                                box-shadow: 0px 22px 20px rgba(51, 51, 51, 0.15);
                            }
                            .circle {
                                background: linear-gradient(90.49deg, rgba(215, 90, 113, 0.5) -7.82%, rgba(95, 58, 198, 0.5) 91.06%);
                            }
                            .text {
                                color: #DDD1FF;
                                opacity: 0.6;
                                background: none;
                                -webkit-text-fill-color: unset;
                            }
                        }
                    }
                    // !Section 量子位banner相关样式
                }
            }
        }
    }


    .specs {
        width: 738px;
        margin: auto;
        margin-top: 86px;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;

        .spec {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 170px;
            img {
                height: 55px;
            }
            span {
                margin-top: 16px;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.05em;
                color: #606266;
            }
        }
    }

    .full-plan-btn {
        background: #4683F0;
        box-shadow: 0px 16px 24px rgba(70, 131, 240, 0.2), 0px 2px 6px rgba(70, 131, 240, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 100px;
        width: 250px;
        height: 57px;
        font-size: 18px;
        line-height: 25px;

        &:not([disabled]):hover {
            background: #3472E1;
        }
        &:not([disabled]):active {
            background: #4683F0;
        }
    }
}

@keyframes enter-arrow-animate {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(6px, 0);
    }
    50% {
        transform: translate(0, 0);
    }
    75% {
        transform: translate(6px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
