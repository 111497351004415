.ui-common-modal {
    .ant-modal-close-x {
        display: none;
    }
    .ant-modal-content {
        background-color: rgba(255,255,255,0.9);
        background-size: 100% 100%;
        position: relative;
        background-clip: padding-box;
        border: 0;
        border-radius: 20px;
        border: unset;
        pointer-events: auto;
        box-sizing: unset;
        box-shadow: unset;

        .ant-modal-body {
            padding: 12px;
            background-color: transparent;
            .close-button {
                width: 32px;
                cursor: pointer;
                position: absolute;
                right: 8px;
                top: 8px;
            }
            .common-modal-container {
                min-height: 240px;
                padding: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                .common-modal-container-logo{

                }
                .common-modal-container-title{
                    margin: 8px;
                    color: #678AF6;
                    font-weight: bolder;
                    font-size: 24px;
                    .sign-log-text {
                        margin-left: 6px;
                        border-bottom: 2px solid #56DFD7;
                        color: #56DFD7;
                        max-width: 180px; // 8em
                        font-weight: bold;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        cursor: pointer;
                        &:hover {
                            color: #56DFD7;
                            font-weight: bold;
                            border-bottom: 2px solid #56DFD7;
                        }
                    }
                }
                .common-modal-container-hint{
                    margin: 16px;
                    color: #678AF6;
                    font-size: 14px;
                }
                .common-modal-container-button{
                    height: 40px;
                    margin: 8px;
                    background-color: #678AF6;
                    border-radius: 40px;
                    border: 2px solid white;
                    color: white;
                    font-weight: bold;
                    padding: 0 12px;
                    min-width: 120px;
                }
            }
        }
    }
}
