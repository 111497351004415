.ai-courses {
    background: #F7F8FB;
    position: relative;
    padding-top: 120px;

    .courses-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 980px;
        margin: auto;
    }

    .course-category {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 72px;
        width: 140px;
        margin-top: 64px;
        margin-bottom: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-weight: 500;
            font-size: 22px;
            letter-spacing: 0.1em;
            color: #2E3135;
        }
    }

    .courses {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .full-plan-btn {
        margin: 80px 0 112px;
    }
}
