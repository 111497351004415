.activity-qianren-ends {
    .float-button {
        z-index: 201;
        position: fixed;
        right: 17px;
        bottom: 20px;
        cursor: pointer;
        img {
            width: 120px;
            height: auto;
        }
    }
}
.activity-qianren-ends-modal {
    .ant-modal-content {
        background: none;
        box-shadow: none;
    }
}
.activity-qianren-ends-modal-main {
    width: 728px;
    height: 458px;
    position: relative;
    .background-bg {
        position: absolute;
        left: -50px;
        right: -50px;
        top: -50px;
        bottom: -50px;
        background: linear-gradient(178.84deg, #5376A2 44%, #8EA6C4 116.97%);
        border: 4px solid #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
    }
    .background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url(https://staticcdn.boyuai.com/materials/2020/02/23/wlj7slF06rI2VAytHhY31.png!png);background-size: 728px 380px;
        background-repeat: no-repeat;
        background-position-y: 10px;
    }
    .close-button {
        width: 52px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 0px;
    }
    .get-cert {
        position: absolute;
        top: 410px;
        left: 180px;
        cursor: pointer;
        img {
            width: 200px;
            height: auto;
        }
    }
    .view-tags {
        position: absolute;
        top: 410px;
        left: 440px;
        cursor: pointer;
        img {
            width: 177px;
            height: auto;
        }
    }
    .get-credit {
        position: absolute;
        top: 306px;
        left: 381px;
        cursor: pointer;
        img {
            width: 177px;
            height: auto;
        }
    }
    .main-text {
        width: 190px;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #DA5B2D;
        position: absolute;
        left: 384px;
        top: 65px;
    }
    .hubei-text {
        width: 190px;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #DA5B2D;
        position: absolute;
        left: 384px;
        top: 178px;
    }
    .hubei-text-mask {
        width: 190px;
        background: #fff;
        height: 20px;
        left: 384px;
        top: 165px;
        position: absolute;
    }
}
.activity-qianren-ends-modal-qrcodes {
    display: flex;
    > div {
        text-align: center;
    }
}
.ui-qianren-guide {
    img {
        animation: enter-arrow-animate infinite 1s linear;
    }
    @keyframes enter-arrow-animate {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(4px, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}