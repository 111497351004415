.course-card {
    display: flex;
    flex-direction: column;
    width: 230px;
    height: 270px;
    background: #FFFFFF;
    border-radius: 4px;
    transition: 0.3s ease;
    margin: 0 10px 32px;
    position: relative;
    box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.15);
    &:hover {
        transform: translate(-4px, -6px);
    }
    &[data-last-line='true'] {
        margin-bottom: 0;
    }
    .discount-badge {
        position: absolute;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 10px;
        color: #fff;
        background: #F0765F;
        right: -8px;
        top: -8px;
        border-radius: 50%;
    }
    .card-img {
        width: 230px;
        height: auto;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 14px;
    }
    .card-title {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #212121;
    }
    .card-intro {
        font-size: 12px;
        color: #959595;
        margin-top: 16px;
        height: 56px;
        overflow: hidden;
    }
    .card-footer {
        margin-top: 10px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        .left {
            color: #606060;
        }
        .right {
            color: #0D7DFC;
        }
    }
}
