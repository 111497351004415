.my-account-wrapper {
  background-color: white;
  padding: 40px;
  height: 618px;

  .security-row {
    display: flex;
    align-items: flex-start;
    .label {
      display: inline-block;
      width: 27%;
      color: #000000;
      font-size: 16px;
      line-height: 30px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 73%;
      color: #606266;
      font-size: 14px;
      line-height: 30px;

      .content-hint {
        display: flex;
        align-items: center;
        color: #606266;
        font-size: 14px;
        line-height: 30px;
        height: 30px;
      }
      .content-advice {
        font-size: 12px;
        line-height: 22px;
        color: #909399;
      }
      .content-info {
        color: #BCBCBC;
      }
      .ant-btn-primary {
        width: 296px;
        height: 36px;
      }

      .ant-input, .ant-input-affix-wrapper {
        width: 296px;
        height: 36px;
      }
      .ant-input-affix-wrapper .ant-input {
        width: unset;
        height: unset;
        margin-bottom: unset;
      }
      .ant-input-affix-wrapper {
        &::before {
          display: none;
        }
        display: flex;
        justify-content: space-between;
      }
      .ant-form-item {
        margin-bottom: 8px;
      }

      /** 只显示自定义的 <Form.Item> extra */
      .ant-form-item-explain {
        display: none;
      }
      .ant-form-item-extra {
        margin: 16px 0;
        font-size: 12px;
        line-height: 22px;
        color: #909399;
        .error {
          color: #F5222D;
        }
      }
    }
  }
  
  .ant-btn-link {
    padding: 0;
    height: 30px;
  }
  .divider {
    border-top: 1px solid #DBDBDB;
    margin: 24px 0;
  }
}
