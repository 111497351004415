.public-classes {
    padding-top: 120px;
    width: 980px;
    margin: auto;
    .courses {
        margin-top: 72px;
        display: flex;
        justify-content: center;

        .course-card {
            height: 237px;
        }
    }
}
