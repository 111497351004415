.home-title-wrapper {
    text-align: center;
    .home-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0.1em;
        color: #2E3135;
        
        margin-bottom: 24px;
    }
    
    .home-intro {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #606266;
    }
}
