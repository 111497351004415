.activity-qianren-ends-intro-modal {
    .ant-modal-content {
        background: none;
        box-shadow: none;
        .activity-qianren-ends-intro-modal-main {
            width: 1096px;
            height: 553px;
            position: relative;
            .background-bg {
                position: absolute;
                left: -20px;
                right: -20px;
                top: 0px;
                bottom: -50px;
                background: linear-gradient(178.84deg, #5376A2 44%, #8EA6C4 116.97%);
                border: 4px solid #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
            }
            .background {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: url(https://staticcdn.boyuai.com/materials/2020/02/23/UHSiiRSPuYtcNAC3PIIey.png!png);background-size: 728px 380px;
                background-repeat: no-repeat;
                background-position-y: 10px;
                background-size: 1096px 553px;
            }
            .close-button {
                width: 52px;
                cursor: pointer;
                position: absolute;
                right: 20px;
                top: 30px;
            }
            .play {
                position: absolute;
                left: 320px;
                top: 180px;
                width: 449px;
                height: 256px;
            }
            .play-cover {
                position: absolute;
                width: 449px;
                height: auto;
                left: 320px;
                top: 180px;
                cursor: pointer;
            }
            .buy-lesson {
                position: absolute;
                left: 320px;
                top: 480px;
                cursor: pointer;
                z-index: 200;
            }
            .buy-lesson-intro {
                position: absolute;
                left: 10px;
                top: 360px;
                cursor: pointer;
            }
            .buy-course {
                position: absolute;
                left: 580px;
                top: 480px;
                cursor: pointer;
                z-index: 200;
            }
            .buy-course-intro {
                position: absolute;
                left: 760px;
                top: 360px;
                cursor: pointer;
            }
            .mask {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
            .buy-lesson-menu {
                position: absolute;
                top: 508px;
                left: 328px;
                padding-top: 25px;
                padding-left: 9px;
                > img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 190;
                }
                a {
                    position: relative;
                    color: #000;
                    font-size: 12px;
                    padding: 3px 5px 3px 5px;
                    display: flex;
                    width: 167px;
                    white-space: nowrap;
                    overflow: hidden;
                    z-index: 210;
                    &:hover {
                        text-decoration: none;
                        background: #E9EAEF;
                    }
                    > div:first-child {
                        flex: 1;
                        font-weight: 500;
                    }
                }
            }
            .buy-course-menu {
                position: absolute;
                top: 508px;
                left: 587px;
                padding-top: 25px;
                padding-left: 9px;
                > img {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                a {
                    position: relative;
                    color: #000;
                    font-size: 12px;
                    padding: 3px 5px 3px 5px;
                    display: flex;
                    width: 167px;
                    white-space: nowrap;
                    overflow: hidden;
                    z-index: 210;
                    &:hover {
                        text-decoration: none;
                        background: #E9EAEF;
                    }
                    > div:first-child {
                        flex: 1;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}