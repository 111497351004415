.container {
    display: flex;
    .icon {
        color: #31AFF9;
        font-size: 22px;
    }
    .text {
        margin-left: 10px;
    }
    .money {
        margin-left: 1px;
    }
}