.my-lessons-wrapper {
  padding-bottom: 20px;
  background-color: white;
  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-tabs-nav-wrap {
    padding-left: 20px;
  }
  .ant-pagination {
    margin-left: 20px;
    margin-top: 20px;
  }
  .content-wrapper {
    background: #f8f9fa;
    //min-height: 90vh;
    &[data-no-lesson='true'] {
      background: white;
    }
    .no-lesson {
      margin: 20px;
    }
    .operation-wrapper {
      color: #7f7f7f;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      .order-wrapper {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .ant-select-selection {
          width: 110px;
        }
      }
      .filter-wrapper {
        span.anticon {
          font-size: 16px;
          margin-right: 5px;
        }
        display: flex;
        align-items: center;
        &:hover,
        &[data-filter='true'] {
          color: #25aefc;
        }
      }
    }
  }

  .lesson-card {
    height: 120px;
    background: white;
    margin-bottom: 10px;
    display: flex;
    padding: 11px 22px;
    flex-direction: column;
    justify-content: space-around;
    position: relative;

    .start-learn {
      cursor: pointer;
      position: absolute;
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      width: 107px;
      height: 36px;
      color: white;
      right: 20px;
      background: linear-gradient(270deg, #4bcbf4 0%, #53aff7 100%);
      box-shadow: 2px 2px 10px rgba(68, 193, 255, 0.5);
      border-radius: 33px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      .tag {
        width: 50px;
        height: 18px;
        font-size: 12px;
        display: flex;
        color: white;
        background: #25aefc;
        border-radius: 10px;
        margin-right: 20px;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 16px;
        color: #515151;
        cursor: pointer;
        &:hover {
          color: #25aefc;
        }
      }
    }

    .info-wrapper {
      display: flex;
      color: #7f7f7f;
      align-items: center;
      width: 400px;
      justify-content: space-between;
      font-size: 12px;
      line-height: 22px;

      .expires[data-red='true'] {
        color: red;
      }
    }
  }
}
