.author-introduction, .video-info-tabs, .ant-modal-body {
    .author {
        display: flex;
        border-bottom: 1px solid #E1E1E1;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
        .cover-image {
            width: 160px;
            height: 160px;
            margin-right: 20px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
        .author-info {
            flex: 1;
            font-size: 14px;
            color: #5A5A5A;
            h2 {
                font-size: 26px;
                color: #252525;
            }
            h3 {
                font-size: 18px;
                color: #252525;
            }
        }
        .ui-markdown {
            font-size: 14px;
            color: #5a5a5a;
        }
    }
}