.course-price-wrapper {
    .price {
        color: #fff;
        font-size: 14px;
        padding: 5px 20px;
        display: flex;
        align-items: center;
        > div {
            flex-shrink: 0;
        }
        &-number {
            font-size: 26px;
            margin-right: 10px;
            color: #FFC537;
        }
        .original-price {
            text-decoration: line-through;
            margin-right: 10px;
        }
        .icon {
            margin-right: 10px;
        }
        .hint {
            opacity: 0.4;
            font-size: 14px;
            padding-left: 10px;
        }
        .spliter {
            margin: 0 10px;
        }
        &.price-origin {
            .price-number {
                color: #fff;
                text-decoration: line-through;
            }
        }
    }
    &[data-is-light-mode='true'] {
        .price {
            color: #444;
            &.price-origin {
                .price-number {
                    color: #aaa;
                }
            }
        }
    }
}