.ui-code-page-card {
    background: #fff;
    padding: 24px 48px;
    border-radius: 5px;
    margin-bottom: 20px;
    user-select: none;
    .title {
        display: flex;
        align-items: center;
        cursor: pointer;
        &-main {
            margin-right: 10px;
            font-size: 16px;
        }
    }
    .subtitle {
        font-size: 16px;
        color: #252525;
        position: relative;
        padding-left: 15px;
        line-height: 20px;
        margin-bottom: 15px;
        margin-top: 20px;
        &:before {
            content: '';
            background: #31aff9;
            border-radius: 2px;
            width: 4px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .card-markdown {
        background: #fafafa;
        border: 1px solid rgba(0, 0, 0, 0.09);
        border-radius: 4px;
        padding: 1em 1em 0 1em;
        margin-bottom: 15px;
    }
}