.ui-card {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    &-header {
        display: flex;
        justify-content: space-between;
        .fold-title {
            cursor: pointer;
            margin-left: 15px;
            color: #31AFF9;
            font-size: 14px;
        }
    }
    &-option {
        display: flex;
        color: #595959;
        .header-author {
            display: flex;
            margin-left: 31px;
            .images {
                white-space: nowrap;
                .img-wrapper {
                    width:24px;
                    height:24px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-left: -24px;
                    position: relative;
                    overflow: hidden;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                img {
                    width: 100%;
                }
            }
            .author-names {
                white-space: nowrap;
            }
            .info {
                cursor: pointer;
                color: #2FB1F8;
                margin-left: 10px;
            }
        }
        .course-title {
            display: flex;
            img {
                height: 26px;
                width: 26px;
                margin-right: 10px;
            }
        }
        .header-count {
            display: flex;
            margin-right: 10px;
            img {
                height: 26px;
                width: 26px;
                margin-right: 10px;
            }
        }
    }
    &-title {
        font-size: 16px;
        color: #252525;
        position: relative;
        padding-left: 10px;
        line-height: 20px;
        display: flex;
        &:before {
            content: '';
            background: #31AFF9;
            border-radius: 2px;
            width: 4px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
        }
        .header-bought {
            display: flex;
            font-size: 12px;
            padding: 4px 10px;
            background: #ECF8FF;
            align-items: center;
            border-radius: 14px;
            margin-left: 10px;
            margin-top: -6px; // 为了实现上下对齐的假象
            color: #31AFF9;
            img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }
        }
    }
    &-body {
        margin-top: 20px;
        height: calc(100% - 40px);
        overflow-x: auto;
    }
}