.page-comment-block {
  background-color: white;
  padding: 8px 16px 0 16px;
  border-top: 12px solid #f8f8f8;
  .comment-header {
    display: flex;
    justify-content: space-between;
    color: #24292e;
    align-items: center;
    font-size: 18px;
    .type-icon {
      width: 4em;
      border-radius: 30px;
      color: white;
      text-align: center;
      font-size: 12px;
      font-weight: lighter;
    }
    .type-icon-question {
      background-color: #ffad61;
    }
    .type-icon-reply {
      background-color: #44c873;
    }
    .type-icon-other {
      background-color: #44c5c8;
    }
    .type-icon-note {
      background-color: #f878aa;
    }
    a {
      color: #2fb1f8;
      font-weight: 900;
      text-decoration: none;
      &:hover {
        color: #1890ff;
      }
    }
    .space {
      flex: 1;
    }
  }
  .comment-body {
    margin: 4px 0;
    border-radius: 3px;
    background: #fff;
    padding: 16px 0;
    .parent {
      color: #b6b6b6;
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      .parent-content {
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
      }
    }
    .editor-fold-wrapper {
      .editor-wrapper.preview {
        max-width: unset;
        &[data-is-fold='true'] {
          margin-bottom: 30px;
        }
      }
      .fold-button {
        left: calc(50% - 54px); // 54是“+点击展开评论”的半宽
      }
    }
  }
  .comment-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    > div {
      &.counts {
        display: flex;
        flex-direction: row;
        > span {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            margin-right: 5px;
          }
          margin: 0 16px;
          &:first-child {
            margin-left: 0;
          }
        }
        > span.pointer {
          cursor: pointer;
        }
      }
      &.link {
        font-size: 12px;
        margin: 0 16px;
        button {
          outline: none;
          display: flex;
          align-items: center;
          border: none;
          background: none;
          cursor: pointer;
          color: #969696;
          margin-right: 10px;
          &:first-child {
            padding-left: 0;
          }
          .anticon {
            margin: 2px;
          }
          img {
            width: 15px;
            margin-right: 5px;
          }
          &[data-is-like='false'] {
            &:hover {
              img {
                filter: invert(59%) sepia(98%) saturate(5417%)
                  hue-rotate(207deg) brightness(105%) contrast(102%);
              }
              color: #297fff;
            }
          }
        }
      }
      &.space {
        flex: 1;
      }
      &.author {
        font-size: 12px;
        margin: 0 8px;
        color: #a6a6a6;
        max-width: 20em;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        cursor: pointer;
      }
      &.time {
        width: 100px;
        font-size: 12px;
        color: #a6a6a6;
        text-align: right;
        white-space: nowrap;
      }
      &.delete {
        //color: #6a737d;
        color: #a6a6a6;
        font-size: 12px;
        cursor: pointer;
        margin-left: 16px;
      }
    }
    .badge {
      margin: 0 10px 1px 5px;
      vertical-align: text-bottom;
      height: 14px;
      width: 14px;
      cursor: pointer;
    }
  }
}
