.lesson-page {
    min-height: 500px;
    background: #fff;
    border-radius: 4px;
    .price {
        font-size: 14px;
        margin-left: 30px;
        display: flex;
        .spliter {
            font-size: 22px;
            margin: 0 20px;
        }
        .cloud {
            display: flex;
            align-items: center;
            img {
                width: 23px;
                height: 20px;
            }
            .original-price {
                color: #A6A6A6;
                text-decoration: line-through;
                margin: 0 10px;
            }
            .sell-price {
                font-size: 22px;
            }
        }
        .drop {
            display: flex;
            align-items: center;
            img {
                width: 15px;
                height: 20px;
            }
            .original-price {
                color: #A6A6A6;
                text-decoration: line-through;
                margin: 0 10px;
            }
            .sell-price {
                font-size: 22px;
            }
        }
    }
    .buy {
        margin: 20px 30px;
        display: flex;
        .bought-day {
            background: #ECF8FF;
            color: #008DFF;
            padding: 8px 16px;
            display: inline-block;
            border-radius: 6px;
        }
        > button {
            margin-right: 10px;
        }
    }
    .lesson-relations {
        display: flex;
        padding: 0 25px;
        .block {
            width: 300px;
            margin-right: 20px;
            flex-shrink: 0;
            &-title {
                display: flex;
                justify-content: center;
                width: 230px;
                height: 50px;
                margin: 30px auto;
                color: white;
                background: var(--relation-color);
                font-weight: bold;
                align-items: center;
                border-radius: 15px;
                .icon {
                    margin-right: 8px;
                }
                .text {
                    font-size: 16px;
                    line-height: 23px;
                }
                .number {
                    background: #00000033;
                    padding: 0 10px;
                    font-size: 14px;
                    border-radius: 10px;
                    margin-left: 8px;
                }
            }
            &-content {
                background: #F5F6FA;
                padding: 40px 25px;
                border-radius: 25px;
                height: 500px;
                overflow-y: auto;
                .empty {
                    text-align: center;
                    padding-top: 60px;
                    img {
                        margin-left: 20px;
                    }
                }
                .lesson {
                    display: flex;
                    align-items: center;
                    border-radius: 15px;
                    height: 48px;
                    border: 2px solid var(--relation-color);
                    margin-bottom: 15px;
                    background: #fff;
                    font-weight: bold;
                    color: unset;
                    cursor: pointer;
                    .icon {
                        border-radius: 30%;
                        margin: 0 10px;
                        padding: 2px;
                        border: 2px solid var(--relation-color);
                        span.anticon {
                            width: 22px;
                            height: 22px;
                            border-radius: 30%;
                            color: var(--relation-color);
                            flex-shrink: 0;
                            padding: 0;
                            font-size: 19px;
                            line-height: 25px;
                        }
                        &[data-new='false'] {
                            background: var(--relation-color);
                            text-align: center;
                            span.anticon {
                                color: #fff;
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                    .title {
                        white-space: nowrap;
                        width: 210px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .lesson-desc {
        display: flex;
        padding: 0 25px;
    }
}
