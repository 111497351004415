.my-progress-wrapper {
  background-color: white;
  padding: 10px;
  .bar-info {
    background: white;
    display: flex;
    height: 40px;
    padding: 0 20px;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
    .wrapper {
      display: flex;
      margin-left: 50px;
      align-items: center;
      .circle {
        width: 66px;
        height: 20px;
        border-radius: 10px;
        margin-right: 10px;
        &[data-status='0'] {
          background: linear-gradient(
            90deg,
            #5468ff 0%,
            #5468ff 0.01%,
            #00a9ff 39.77%,
            #00bcff 99.16%
          );
        }
        &[data-status='1'] {
          background: linear-gradient(
            90deg,
            #faae16 0%,
            #faae16 0.01%,
            #facc18 99.16%
          );
        }
        &[data-status='2'] {
          background: #f2f4f7;
        }
      }
    }
  }
  .items {
    //min-height: 100vh;
  }
  .pagination {
    padding: 10px 20px 0;
  }
  .progress-item {
    height: 150px;
    border-top: 1px solid #e7e7e7;
    padding: 27px 17px;
    display: flex;
    align-items: center;
    position: relative;
    background: white;
    .cover {
      width: 130px;
      height: 90px;
      cursor: pointer;
    }

    .learn-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 17px;
      top: 27px;
      cursor: pointer;
      font-size: 16px;
      width: 107px;
      background: #5468ff;
      box-shadow: 2px 2px 22px rgba(56, 128, 248, 0.41);
      border-radius: 9px;
      color: white;
      height: 32px;
      span.anticon {
        margin-right: 10px;
      }
      img {
        width: 13px;
        margin-right: 10px;
      }
    }

    .unlock-learn {
      font-size: 12px;
    }

    .finish-learn {
      color: #52c41a;
      background: #fff;
      box-shadow: unset;
    }

    .content-wrapper {
      margin-left: 20px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-around;
      height: 100px;

      .title {
        color: #5a5a5a;
        font-size: 16px;
        span {
          cursor: pointer;
          &:hover {
            color: #25aefc;
          }
        }
      }

      .author {
        cursor: pointer;
        span.anticon {
          margin-right: 10px;
        }
      }
    }

    .ant-progress-inner,
    .ant-progress-bg,
    .ant-progress-success-bg {
      height: 15px !important;
    }

    .ant-progress-bg {
      background: linear-gradient(
        90deg,
        #faae16 0%,
        #faae16 0.01%,
        #facc18 99.16%
      );
    }

    .ant-progress-success-bg {
      background: linear-gradient(
        90deg,
        #5468ff 0%,
        #5468ff 0.01%,
        #5468ff 27.89%,
        #00bcff 99.16%
      );
    }

    .progress-wrapper {
      display: flex;
      color: #5468ff;
      .number {
        margin-left: 20px;
      }
    }
  }
}
