.course-package-buy {
    text-align: center;
    margin-top: 20px;
    button {
        border: none;
        border-radius: 38px;
        font-size: 20px;
        padding: 10px 60px;
        background: #51B0F3;
        color: #fff;
        outline: none;
        cursor: pointer;
        position: relative;
        &:active {
            box-shadow: none;
        }
        .discount-flag {
            position: absolute;
            right: 20px;
            top: -10px;
            width: 27px;
            img {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            div {
                position: relative;
                color: #D96D24;
                font-size: 12px;
            }
        }
    }
    &[data-skin='small'] {
        margin-top: 0;
        button {
            border: 1px solid #51B0F3;
            background: #fff;
            color: #51B0F3;
            font-size: 14px;
            padding: 5px 15px;
            border-radius: 5px;
        }
        .discount-flag {
            right: -15px;
        }
    }
    &[data-skin='middle'] {
        button {
            font-size: 16px;
            padding: 10px 50px;
        }
    }
}