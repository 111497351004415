.op-wrapper {
  display: flex;
  justify-content: space-between;
}

.widget {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  height: 80vh;
  
  textarea {
    width: 49%;
  }
}