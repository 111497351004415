.ui-referral {
    z-index: 201;
    position: fixed;
    right: 17px;
    bottom: 20px;
    > div {
        cursor: pointer;
        position: relative;
        img {
            position: relative;
            z-index: 202;
        }
        img.i1 {
            position: absolute;
            left: 14px;
            top: -2px;
        }
        img.i2 {
            position: absolute;
            left: 24px;
            top: 2px;
        }
        img.number {
            position: absolute;
            left: 50%;
            margin-left: -37px;
            top: -65px;
            z-index: 201;
        }
    }
}
.ui-referral-modal {
    position: relative;
    height: 450px;
    margin: -32px -36px;
    overflow: hidden;
    img.background {
        width: 603px;
        height: 457px;
        position: absolute;
        left: 0;
        top: 0;
    }
    .content {
        text-align: center;
        position: relative;
        padding: 100px 0;
        .title {
            color: #002968;
            font-size: 18px;
            font-weight: bolder;
            margin-bottom: 20px;
        }
        .hint {
            color: #1FB64C;
        }
    }
}
