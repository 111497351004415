.ui-login-form {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        height: 40px;
        .ant-input-affix-wrapper {
            height: 40px;
            background: #F1F1F1;
            border-radius: 10px;
            border: none;
        }
        .ant-input-group {
            height: 40px;
            .ant-select {
                background: #F1F1F1;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border: none;
                height: 40px;
                .ant-select-selector {
                    top: 5px;
                }
            }
            .ant-input-affix-wrapper {
                border-radius: 0 10px 10px 0;
            }
        }
        img {
            height: 20px;
        }
    }
    button {
        border-radius: 10px;
        box-shadow: 4px 4px 12px rgba(38, 163, 239, 0.25);
        background-color: #2FB1F8;
        border: none;
        color: #fff;
        height: 40px;
        margin-left: 15px;
        &:disabled {
            color: rgba(0,0,0,0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
            box-shadow: none;
        }
        &:hover {
            background-color: #2FB1F8;
            color: #fff;
        }
    }
    .login-button {
        width: 100%;
        margin-left: 0;
    }
    .agreement {
        color: #888;
        margin-top: 12px;
    }
}
