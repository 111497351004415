.page-user {
  background: #f1f1f1;
  padding: 20px;
  margin-top: 64px;
  min-height: 100vh;
  .main {
    margin: 0 auto;
    max-width: 1100px;
    display: flex;
    align-items: stretch;
    .left {
      flex: 0 0 320px;
      margin-right: 20px;
      background: #fff;
      padding-top: 40px;
      height: 872px;
      .portrait {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        border: 1px solid #979797;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
      .name {
        margin: 20px auto;
        font-size: 20px;
        color: #252525;
        text-align: center;
        border-bottom: 2px solid #e2e2e2;
        width: 80px;
        padding-bottom: 10px;
      }
      .consumed-group {
        display: flex;
        justify-content: space-around;
        .consumed-label {
          color: #7f7f7f;
          text-align: center;
        }
        .consumed {
          font-size: 24px;
          color: #252525;
          margin: 10px 0 20px;
          text-align: center;
        }
      }
      .left-menu {
        .ant-menu-item {
          font-size: 16px;
          color: #252525;
          line-height: 60px;
          padding: 0 60px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 60px;

          &-selected {
            background: #e5f5ff;
            color: #31aff9;
          }

          &:hover {
            background: rgb(239, 247, 252, 0.1);
          }

          .ant-badge {
            font-size: 16px;
          }
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;
      .balance-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        margin-right: -10px;
        justify-content: space-between;
      }
      .balance-wrapper,
      .cloud-wrapper {
        background-image: linear-gradient(90deg, #63baff 0%, #5cd8ff 100%);
        box-shadow: 4px 4px 16px 0 rgba(68, 193, 255, 0.5);
        border-radius: 5px;
        flex: 360px 1;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 20px;
        position: relative;
        .balance-label {
          display: flex;
          align-items: center;
          span.anticon {
            font-size: 14px;
            margin-left: 10px;
          }
          img {
            margin-right: 20px;
          }
          font-size: 20px;
          color: #fff;
          margin-bottom: 30px;
        }
        .balance {
          margin-left: 20px;
          font-size: 40px;
          color: #fff;
          line-height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 52px;
            height: 62px;
          }
        }
        .recharge {
          background: rgba(255, 255, 255, 0.13);
          border: 2px solid #ffffff;
          border-radius: 20px;
          width: 120px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          line-height: 36px;
          position: absolute;
          right: 20px;
          top: 16px;
          text-align: center;
          cursor: pointer;
        }
      }
      .cloud-wrapper {
        background-image: linear-gradient(90deg, #879eff 0%, #86aeff 100%);
        box-shadow: 4px 4px 16px 0 rgba(193, 206, 245, 1);
        .balance {
          img {
            width: 70px;
            height: 60px;
          }
        }
      }
      .consumed-box {
        background: #fff;
        margin-top: 20px;
        flex: 1;
        h3 {
          font-weight: normal;
          margin: 10px;
        }
      }
      .ui-card {
        max-height: calc(100vh - 250px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .display-message {
        max-width: 400px;
      }
      .display-type {
        min-width: 75px;
      }
      .display-time {
        min-width: 180px;
      }
      .display-value {
        min-width: 50px;
      }
    }
  }
}
