.oj-wrapper {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 84px 20px 20px 20px;
    background: #f1f1f1;
    .contents {
      background-color: #fff;
      padding: 16px;
    }

    .comment-wrapper {
      margin-top: 16px;
      padding: 16px;
      background-color: #fff;

      h4 {
        color: #1890ff;
        margin-bottom: 16px;
      }
    }
}
