.sidebar {
    width: 300px;
    flex-shrink: 0;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    left: 0;
    background: #fff;
    z-index: 80;
    .toggle-all {
        position: absolute;
        top: 20px;
        right: 10px;
        color: #31AFF9;
        cursor: pointer;
        z-index: 1;
        span.anticon {
            margin-right: 5px;
        }
        display: flex;
        .close-btn {
            color: #f93131;
            margin-left: 15px;
        }
    }
    .ui-card {
        padding: 0;
        overflow: visible;
        .ui-card-header {
            padding: 20px;
            position: sticky;
            top:0;
            background: #fff;
            z-index: 5;
            a {
                color: #252525;
            }
        }
        .ui-card-body {
            margin-top: 0;
        }
    }
    .title {
        background: #FAFAFA;
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.09);
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        &[data-not-ready='true'] {
            cursor: not-allowed;
        }
        &[data-at-current='true'] {
            background: #E5F5FF;
            &:before {
                content: '';
                position: absolute;
                width: 5px;
                top: 0;
                bottom: 0;
                left: 0;
                background: #31AFF9;
            }
        }
        .title-wrapper {
            display: flex;
            align-items: center;
            min-width: 0;
            flex: 1;
            img {
                width: 27px;
                height: 27px;
                margin-right: 10px;
                flex: 0;
            }
            a {
                color: unset;
                min-width: 200px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .title-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                &[data-has-videos='false'] {
                    a {
                        display: unset;
                    }
                }
            }
        }
        .info-wrapper {
            display: flex;
            align-items: center;
            .feedback-button {
                margin: 0 10px 0 6px;
                color: #31AFF9;
            }
            i.fold-button {
                font-size: 14px;
                margin-right: 10px;
            }
            .coming-soon{
                font-size: 14px;
                margin: 0 5px;
            }
        }
    }
    &[data-is-collapsed='true'] {
        width: 6px;
        background: #31AFF9;
        .title, .sidebar-container > ul > li > ul > li.active {
            background: #31AFF9;
            transition: all 0.3s ease-out;
            pointer-events: none;
        }
    }
    .collapse-left {
        position: absolute;
        width: 16px;
        height: 35px;
        right: -16px;
        top: 19px;
        cursor: pointer;
    }
    .sidebar-container {
        overflow: hidden;
        white-space: nowrap;
        h2 {
            padding: 20px;
            font-size: 20px;
            color: #252525;
            font-weight: normal;
            margin-bottom: 0;
            span.anticon {
                color: #31AFF9;
            }
        }
        > ul {
            padding: 0;
            > li {
                list-style: none;
                .learn-status {
                    font-size: 12px;
                    color: #31AFF9;
                    span.anticon {
                        margin-right: 10px;
                    }
                    img {
                        width: 13px;
                        height: 13px;
                        margin-right: 10px;
                    }
                    &.pending {
                        color: #FFBD6D;
                    }
                    &.success {
                        color: #43CB69;
                    }
                }
                .lock i{
                    margin-right: 0;
                }
                > ul {
                    padding: 0;
                    > li {
                        font-size: 14px;
                        color: #666;
                        display: block;
                        cursor: pointer;
                        position: relative;
                        .course-item-title {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        > div {
                            padding: 15px 21px 15px 50px;
                            color: #666;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        &:hover {
                            background: #F4FBFF;
                        }
                        &[data-is-active="true"] {
                            background: #E5F5FF;
                            &:before {
                                content: '';
                                position: absolute;
                                width: 5px;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                background: #31AFF9;
                            }
                        }
                        &[data-is-forbidden="true"] {
                            > div {
                                color: #999;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 90vw;
    height: 100vh;
    overflow-y: auto;
}

.show-btn {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 999;
    width: 30px;
    background-color: #fff;
    padding: 8px;
    line-height: 20px;
    box-shadow: 1px 3px 10px #242424a6;
    border-radius: 0 5px 5px 0;
}