.footer-wrapper {
    background-image: url('https://staticcdn.boyuai.com/materials/2020/11/13/zBh3eKJIROFNZGTG6eXL3.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .footer {
        height: 120px;
        width: 1200px;
        margin: auto;
        padding-top: 26px;
        position: relative;

        .logo {
            margin-left: 80px;
        }

        .contacts {
            position: absolute;
            right: 80px;
            padding-top: 2px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            .contact {
                cursor: pointer;
                margin-left: 80px;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.1em;
                color: #FFFFFF;

                .anticon {
                    font-size: 24px;
                    margin-left: 16px;
                }
            }
        }
        .copyright {
            display: flex;
            align-items: center;
            text-align: right;
            margin-right: 80px;
            color: #909399;
            position: absolute;
            bottom: 16px;
            right: 0;
            font-size: 14px;
            line-height: 24px;
            a {
                color: #909399;
                margin-left: 10px;
            }
        }
    }
}

.home-contact-popover .ant-popover-inner-content {
    padding: 16px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        margin-bottom: 8px;
        width: 87px;
    }
    div {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #606266;
    }
}
