.video-page {
    max-width: 100vw;
    @media (max-width: 540px) {
        .ui-card-header {
            flex-direction: column;
        }
        .ui-card-title {
            margin-bottom: 16px;
        }
    }
    .video-info-tabs {
        &[data-video-empty='true'] {
            margin-top: 0;
        }
        // 因为需要设置讨论区的 sticky 属性，所以父元素不能有 overflow 属性
        .ant-tabs {
            overflow: unset;
        }
        background: #fff;
        padding: 0 20px 20px;
        margin-top: 20px;
        min-height: 280px;

        .ant-tabs-nav {
            color: #a8a8a8
        }

        .ant-tabs-ink-bar {
            color: #31AFF9;
        }

        .ant-tabs-tab:hover, .ant-tabs-tab-active {
            color: #5a5a5a;
            font-weight: 500;
        }

        .desc-wrapper {
            .title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }
}