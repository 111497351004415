.page-recall-reward{
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 128px);
    .recall-reward-counter {
        min-height: 200px;
        font-weight: 600;
        font-size: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div{
            margin: 0
        }
    }
    .recall-reward-jump {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        button {
            width: 200px;
            height: 50px;
            background: linear-gradient(90deg, #4BCBF4 0%, #53AFF7 100%);
            box-shadow: 4px 4px 16px 0 rgba(68,193,255,0.50);
            border-radius: 33.5px;

            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: -0.31px;
            text-align: center;
        }
    }
}
