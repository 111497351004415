.video-page {
    color: #252525;
    transition: all 0.3s ease;
    padding-bottom: 100px;
    .video-title {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .video-description {
        font-family: PingFangSC-Regular;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 12px 26px 22px;
        .title {
            font-size: 16px;
            letter-spacing: -0.31px;
            text-align: left;
            position: relative;
            line-height: 20px;
            &:before {
                content: '';
                background: #31AFF9;
                border-radius: 2px;
                width: 4px;
                height: 20px;
                position: absolute;
                left: -10px;
                top: 0;
            }
        }
        .description {
            margin-top: 18px;
            font-size: 14px;
            color: #5A5A5A;
            letter-spacing: -0.27px;
            text-align: left;
        }
    }
    .comment-wrapper {
        margin-top: 20px;
    }
    .ui-card .ui-card-body {
        overflow-x: unset;
    }
    .prism-player .prism-big-play-btn {
        right: 30px !important;
        bottom: 55px !important;
        left: auto !important;
    }
    .fe-player-tooltip-wrap {
        left: 10px;
        right: auto;
    }
}