@import '~antd/dist/antd.css';

html {
    scroll-behavior: smooth;
}

.mt-2 {
    margin-top: 20px;
}
.mt-3 {
    margin-top: 30px;
}
.ml-2 {
    margin-left: 20px;
}
.mb-1 {
    margin-bottom: 10px;
}
.mb-2 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 30px;
}
.d-flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}
.flex-shink-0 {
    flex-shrink: 0;
}
.inline-w-2 {
    display: inline-block;
    width: 20px;
}