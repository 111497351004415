.activity-qianren-laxin {
    .float-button {
        z-index: 201;
        position: fixed;
        right: 18px;
        bottom: 30px;
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 4px solid white;
        background-color: #53759b;
        box-shadow: 0px 4px 22px rgba(49, 121, 247, 0.2);
        .free {
            z-index: 201;
            width: 54px;
            position: absolute;
            top: 8px;
            right: -10px;
        }
        .sakura {
            z-index: 200;
            width: 100px;
            height: auto;
            position: absolute;
            top: 6px;
            left: -24px;
        }
        .text {
            z-index: 201;
            width: 54px;
            position: absolute;
            top: 35px;
            left: 0px;
        }
        &:hover {
            .sakura {
                z-index: 200;
                width: 100px;
                position: absolute;
                top: 6px;
                left: -24px;
                transform-origin: 52px 48px;
                animation: rotate 8s linear infinite;
            }
        }
        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
        //img {
        //    width: 110px;
        //    height: auto;
        //}
    }
}

.activity-qianren-laxin-modal {
    .ant-modal-close-x {
        display: none;
    }
    .ant-modal-content {
        width: 890px;
        height: 600px;
        background-color: rgba(255, 255, 255, 0);
        background-image: url('https://staticcdn.boyuai.com/materials/2020/02/29/_4G1J9VrEMJXr52isPBGX.png!png');
        background-size: 100% 100%;
        position: relative;
        background-clip: padding-box;
        border: 0;
        border-radius: 4px;
        border: unset;
        pointer-events: auto;
        box-sizing: unset;
        box-shadow: unset;
        .close-button {
            width: 52px;
            cursor: pointer;
            position: absolute;
            right: 50px;
            top: 50px;
        }
        .activity-qianren-laxin-modal-content {
            .activity-qianren-laxin-modal-content-title {
                position: absolute;
            }
            .activity-qianren-laxin-modal-content-thanks {
                position: absolute;
            }
            .activity-qianren-laxin-modal-content-box {
                position: absolute;
                margin: 16px;
                width: 810px; //宽890 padding 24 margin 16 刚好810
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 20px;
                padding: 30px 40px 20px 40px;
                display: flex;
                flex-direction: column;
                .activity-qianren-laxin-modal-content-box-title {
                    position: absolute;
                    top: -18px;
                    left: 40px;
                    height: 36px;
                    padding: 0 16px;
                    background-color: #ffdcdc;
                    border-radius: 14px;
                    color: #8092ab;
                    font-weight: bold;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .activity-qianren-laxin-modal-content-box-content {
                    color: white;
                    line-height: 24px;
                    font-size: 15px;
                    font-weight: 600;
                    .activity-qianren-laxin-modal-content-box-content-highlight {
                        padding-bottom: 3px;
                        border-bottom: 1px solid #ffdcdc;
                        span {
                            color: #ffdcdc;
                            font-size: 20px;
                            font-weight: bold;
                            border-bottom: 1px solid #ffdcdc;
                        }
                    }
                }
                .activity-qianren-laxin-modal-content-box-reward {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    .activity-qianren-laxin-modal-content-box-reward-text {
                        margin-left: 4px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .activity-qianren-laxin-modal-content-box-reward-link {
                            margin: 0 6px;
                            padding-bottom: 1px;
                            border-bottom: 1px solid #ff8585;
                            color: #ff8585;
                            max-width: 120px; // 8em
                            font-weight: bold;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &:hover {
                                padding-bottom: 0;
                                border-bottom: 2px solid #e16363;
                                color: #e16363;
                            }
                        }
                    }
                }
                .activity-qianren-laxin-modal-content-box-extra {
                    margin-top: 8px;
                    color: #53759b;
                    font-size: 13px;
                    font-weight: 600;
                }
                .activity-qianren-laxin-modal-content-box-button {
                    width: 188px;
                    height: 52px;
                    border: 2px solid white;
                    border-radius: 26px;
                    background: #53779a;
                    box-shadow: 0px 11px 22px rgba(145, 177, 255, 0.2);
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        .circle {
                            img {
                                animation: enter-arrow-animate 1 0.6s linear;
                            }
                        }
                    }
                    .circle {
                        width: 38px;
                        height: 38px;
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 6px;
                        border-radius: 50%;
                        font-size: 14px;
                        font-weight: bold;
                    }
                    .text {
                        text-align: center;
                        flex: 1;
                        font-size: 14px;
                        margin-right: 20px;
                        color: #fff;
                        &.space {
                            letter-spacing: 2px;
                        }
                    }
                }
                .activity-qianren-laxin-modal-content-box-progress {
                    width: 720px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    .activity-qianren-laxin-modal-content-box-progress-track {
                        position: absolute;
                        width: 680px;
                        left: 10px;
                        height: 12px;
                        border-top: 3px solid #dedede;
                        border-bottom: 3px solid #dedede;
                        .activity-qianren-laxin-modal-content-box-progress-bar {
                            height: 6px;
                            background-image: url('https://staticcdn.boyuai.com/materials/2020/03/01/uDcZlMhL4rhboXio2ZM9i.png!png');
                            //background: linear-gradient(
                            //        -60deg,
                            //        #AFB7C8 25%,
                            //        #53779A 0,
                            //        #53779A 60%,
                            //        #AFB7C8 0,
                            //        #AFB7C8 75%,
                            //        #53779A 0
                            //);
                            //background-size: 12px 16px;
                        }
                    }
                    img {
                        z-index: 1;
                    }
                    .activity-qianren-laxin-modal-content-box-progress-check {
                        width: 30px;
                        height: 30px;
                        margin: 25px;
                        z-index: 1;
                        border-radius: 50%;
                        border: 2px solid white;
                        background: #afb7c8;
                        font-size: 10px;
                        font-weight: bolder;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            display: none;
                            width: 15px;
                            height: 15px;
                            margin-bottom: 3px;
                        }

                        &[data-checked='true'] {
                            background: linear-gradient(
                                17.49deg,
                                #e99b9b 8.45%,
                                #ffdcdc 79.02%
                            );
                            img {
                                display: unset;
                            }
                        }
                    }
                }
                .scroll-without-bar-shell {
                    overflow: hidden;
                    .scroll-without-bar-container {
                        width: calc(100% + 17px);
                        height: 100%;
                        overflow: auto;
                    }
                }
            }
            .activity-qianren-laxin-modal-content-explanation {
                position: absolute;
                bottom: 24px;
                right: 40px;
                color: white;
                font-size: 10px;
                font-weight: lighter;
                cursor: pointer;
            }
        }
        .pending {
            position: relative;
            left: calc((890px - 200px) / 2 - 30px);
            top: 220px;
            width: 200px;
            .loading {
                z-index: 201;
                width: 160px;
                position: absolute;
                top: 50px;
                right: 8px;
            }
            .sakura {
                z-index: 200;
                width: 150px;
                height: auto;
                position: absolute;
                top: 6px;
                left: -24px;
                transform-origin: 76px 70px;
                animation: rotate 8s linear infinite;
            }
            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }
        }
    }

    @keyframes enter-arrow-animate {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(6px, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            transform: translate(6px, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}

.activity-qianren-laxin-courses-popover {
    width: 460px;
    padding: 30px;
    .activity-qianren-laxin-courses-popover-hint {
        position: absolute;
        top: -40px;
        right: -80px;
        background-image: url('https://staticcdn.boyuai.com/materials/2020/03/01/wlJ2dO_omXT-3szuMuRPL.png!png');
        background-size: 100% 100%;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 22px 36px 22px;
    }
    .activity-qianren-laxin-courses-popover-item {
        margin-bottom: 8px;
        color: black;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > img {
            margin: 0 20px 0 12px;
        }
        .activity-qianren-laxin-courses-popover-item-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .activity-qianren-laxin-courses-popover-item-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 200px;
            }
            button {
                width: 60px;
                background-color: #53779a;
                border-radius: 20px;
                color: white;
                font-size: 14px;
                font-weight: bold;
                margin-right: 16px;
                padding: unset;
            }
        }
    }
    .activity-qianren-laxin-courses-popover-item:not(:last-child) {
        border-bottom: 2px solid;
        border-image: linear-gradient(45deg, #53779a, transparent) 30 30;
    }
}

.ui-qianren-guide {
    img {
        animation: enter-arrow-animate infinite 1s linear;
    }
    @keyframes enter-arrow-animate {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(4px, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}
