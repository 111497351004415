.page-login {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    .main {
        width: 460px;
        height: 360px;
        margin: 0 auto;
        background: #fff;
        border-radius: 15px;
        overflow: visible;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 90px;
        font-size: 14px;
        .logo-wrapper {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            top: -5px;
            box-shadow: 4px 4px 12px rgba(38, 163, 239, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-top: -50px;
            background: #fff;
            padding: 0;
            img {
                height: 62px;
                width: 62px;
            }
        }
        .main-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo-name {
                margin: 15px 0 18px 0;
                text-align: center;
                .name-zh {            
                    font-weight: bold;  
                    font-size: 20px;
                }
                .name-eng {
                    font-size: 12px;
                }
            }
        }
    }
    &[data-is-mobile='true'] {
        .main {
            width: 90%;
            padding: 0 20px;
        }
    }
    @media screen and (max-width: 768px) {
        .main {
            width: 90%;
            padding: 0 20px;
        }
    }
}