.balance-detail {
    width: 310px;
    display: flex;
    justify-content: space-between;
    .label {
        margin-left: 10px;
        flex: 1;
    }
    .drop {
        height: 20px;
        width: 15px;
        margin-right: 4px;
        margin-left: 4px;
    }
}