.page-intro-wrapper {
    display: flex;
    flex-direction: column;
    &[data-is-img='true'] {
        .ui-card {
            margin-bottom: 35px;
        }
    }
    .img-wrapper {
        img {
            width: 100%;
            height: auto;
        }

        &[data-lesson-id='29'] {
            img:last-of-type {
                background: #F6F7FB;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        background: #F6F7FB;
        padding: 0 60px;
        height: 700px;
        justify-content: center;
        position: relative;

        .qianghua-footer {
            position: absolute;
            background: #FFFFFF;
            box-shadow: 2px 2px 33px rgba(45, 142, 251, 0.12);
            border-radius: 5px;
            padding: 45px;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            width: 60%;
            img {
                width: 100%;
            }
        }

        .text {
            // width: 567px;
            // height: 471px;
            background: #0074F9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 2px 2px 33px rgba(45, 142, 251, 0.12);
            border-radius: 5px;
            padding: 30px;
            .text-wrapper {
                color: #fff;
                h1 {
                    color: #fff;
                    font-size: 30px;
                }
                .wrapper {
                    font-size: 24px;
                }
            }
            .intro-text {
                width: 368px;
                // height: 311px;
            }
            .button {
                width: 395px;
                cursor: pointer;
                margin-top: 10px;
            }
        }
        .code {
            width: 250px;
            margin-left: 40px
        }
    }
}