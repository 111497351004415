@import '~antd/lib/style/themes/default.less';

@bg-color: #ededed;

.wechat-menu-editor {
  .wechat-menu {
    border-width: 1px;
    border-color: @border-color-base;
    border-style: solid;
    border-radius: @border-radius-base;
    // background-image: url('./wechat-menu-bg.png');
    background-repeat: no-repeat;
    padding-top: 450px;
    background-size: cover;
  }

  .clickable {
    cursor: pointer;
  }

  .editor-bottom {
    margin-top: 8px;
  }

  .menu-item-layout {
    height: 100%;
    padding: 16px;
  }
}
