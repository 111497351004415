.ui-trinket {
    width: 100%;
    height: 380px;
    position: relative;
    .fake-iframe {
        width: 100%;
        height: 365px;
        z-index: 80;
    }
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 90;
    }
}