.ui-buy-course {
    padding: 10px 15px;
    .title-bar {
        font-size: 24px;
        color: #252525;
        display: flex;
        justify-content: space-between;
        .price{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .price-or{
                margin-left: 20px;
                margin-right: 20px;
            }
            .price-original {
                text-decoration: line-through;
                margin-right: 10px;
                margin-left: 10px;
                font-size: 16px;
                color: gray;
            }
        }
    }
    .course-price-wrapper {
        margin-left: -30px;
        .price {
            .spliter:first-child {
                flex: 1;
            }
        }
    }
    .balance-bar {
        margin-top: 28px;
        font-size: 14px;
        color: #252525;
        display: flex;
        justify-content: space-between;
        .payment-options{
            width: 330px;
            display: flex;
            flex-direction: column;
            .payment-option {
                margin-bottom: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .footer {
        margin-top: 40px;
        text-align: center;
        .recharge-hint {
            color: red;
            margin-bottom: 10px;
        }
        button {
            background-image: linear-gradient(90deg, #4BCBF4 0%, #53AFF7 100%);
            box-shadow: 4px 4px 16px 0 rgba(68,193,255,0.50);
            border-radius: 34px;
            width: 200px;
            font-size: 16px;
            height: 50px;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
}
.result-jump {
    font-size: 20px;
}
