.page-user-discussion {
  background-color: white;
  padding: 20px;
  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
  }
}
