.boyu-header-wrapper {
    .header-search-left {
        position: absolute;
        width: 464px;
    }
    .header-search-icon-wrapper {
        display: inline-block;
    }
    .header-search-right {
        width: 389px;
        position: absolute;
        right: 40px;
    }
    .info-wrapper, .right-login-wrapper, .header-search-left {
        transition: transform 0.3s ease-in-out;
        top: 0;
        transform: translateY(0);
    }
    .header-search-left {
        transition: transform 0.3s ease-in-out;
        top: 0;
        left: 182px;
        transform: translateY(0);
    }
    .header-search-right {
        transition: transform 0.3s ease-in-out;
        top: 80px;
        transform: translateY(0);
    }
    &.full { 
        .info-wrapper, .right-login-wrapper, .header-search-left {
            transform: translateY(-64px);
        }
        .header-search-right {
            transform: translateY(-64px);
        }
    }
}

.header-dropdown.search-course-dropdown {
    top: 52px !important;
    position: fixed;
    z-index: 101;
}
