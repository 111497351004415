.ant-result-404 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 120;
    position: relative;
    background: white;
    .ant-result-image {
        margin: unset;
    }
}