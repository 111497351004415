.onboarding-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // 这个层级需要大于其他的层级，小于弹窗的层级
    z-index: 900;
    background: #3E3E3E;
    opacity: 0.3;

    .comment-mask, .filter-mask, .feedback-mask, .current-learning-mask {
        position: absolute;
        background: white;
        z-index: 901;
        opacity: 0;
        &[data-is-visible='true'] {
            opacity: 1;
        }
    }

    .mask-tip {
        span.anticon {
            margin-right: 8px;
        }
        position: absolute;
        color: #ffffff;
        font-weight: bold;
        font-size: 20px;
    }
    
    .comment-mask {
        .mask-tip {
            top: -95px;
            right: 0;
            display: flex;
            span.anticon {
                margin-top: 5px;
            }
        }
    }

    .filter-mask {
        .mask-tip {
            top: -35px;
            left: 0;
        }
    }

    .current-learning-mask {
        .mask-tip {
            left: 320px;
            width: 500px;
            top: 0;
        }
    }
}
.page-course {
    position: relative;
    .feedback-mask-tip {
        z-index: 900;
        top: 140px;
        left: 350px;
        position: absolute;
        color: #ffffff;
        font-weight: bold;
        font-size: 20px;
        span.anticon {
            margin-right: 8px;
        }
    }
    .course-container {
        display: flex;
        position: relative;
        padding: 84px 20px 20px 20px;
        background: #f1f1f1;
        min-height: 100vh;
        .sidebar {
            position: fixed;
            top: 84px;
            left: 20px;
            max-height: calc(100vh - 104px);
        }
        .main {
            position: relative;
            z-index: 81; // 暂时高于sidebar，以便与小屏幕观看
            background: #F1F1F1;
            flex: 1;
            margin-top: 0;
            min-height: calc(100vh - 280px);
            width: calc(100vw - 360px);
            margin-left: 320px;
            // padding-bottom: 50px;
            .introduction {
                display: flex;
                .cover-image {
                    width: 400px;
                    margin-right: 20px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .course-info {
                    flex: 1;
                    font-size: 14px;
                    color: #5A5A5A;
                    h2 {
                        font-size: 26px;
                        color: #252525;
                    }
                    .author {
                        font-size: 18px;
                        color: #8E8E8E;
                    }
                    .course-content {
                        margin-top: 40px;
                    }
                    .course-package-buy {
                        text-align: left;
                        padding-left: 20px;
                    }
                }
                .buy-buttons {
                    display: flex;
                    align-items: baseline;
                    .course-package-buy {
                        flex-shrink: 0;
                        button {
                            font-size: 16px;
                        }
                    }
                    .buy-one {
                        flex-shrink: 0;
                        margin-left: 10px;
                        border: none;
                        border-radius: 38px;
                        font-size: 16px;
                        padding: 10px 30px;
                        background: #FF9933;
                        color: #fff;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
