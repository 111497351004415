.account-balance {
    .credit-expire-hint {
        padding-left: 24px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #707880;
        .warning {
            font-size: 18px;
            margin-right: 8px;
        }
    }
}