.page-admin {
    padding: 80px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    .admin-sidebar {
        width: 250px;
        margin-right: 24px;
        margin-bottom: 20px;
        .ant-menu {
            margin: -23px -25px -24px -24px;
        }
    }
    .main {
        flex: 1;
        min-width: 0;
    }
}
