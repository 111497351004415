.page-gift {
    margin-top: 64px;
    .img-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        background-size: cover;
        &:first-child {
            height: 40vw;
            img {
                width: 25vw;
            }
        }
        &:nth-child(2), &:nth-child(3) {
            height: 55vw;
            img {
                width: 50vw;
            }
        }
        &:last-child {
            height: 25vw;
            overflow: hidden;
            img {
                width: 50vw;
                margin-bottom: -40px;
            }
        }
    }
}