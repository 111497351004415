.mobile-tip {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 300px;
        margin-top: -100px;
    }
    .use-mobile-link {
        color: #32AAF7;
        font-size: 18px;
        margin-bottom: 5px;
    }
    .use-mobile {
        color: #32AAF7;
        font-size: 12px;
        text-decoration: underline;
    }
}
