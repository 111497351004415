.ui-course-quiz {
    .nav-bar {
        background: #fff;
        padding: 10px 15px 5px 15px;
        margin-bottom: 16px;
        h2 {
            span.anticon {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .quiz-body {
        padding: 0 16px;
        background: #fff;
    }
}
