.about-boyu {
    padding-top: 120px;
    .teacher {
        display: block;
        width: 100%;
    }

    .ant-carousel {
        margin-top: 66px;
        margin-bottom: 222px;
        .carousel-item {
            img {
                height: 523px;
                border-radius: 16px;
            }
            .carousel-item-title {
                text-align: center;
                margin-top: 32px;
                letter-spacing: 0.1em;
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                color: #2E3135;
                user-select: none;
            }
        }

        .slick-arrow {
            z-index: 1;
            width: 54px;
            height: 54px;
            img {
                position: relative;
                top: -52px; // 底部说明文字高度 62px，外层还有 margin-top: -10px
            }
        }
        .slick-disabled {
            img {
                opacity: 0.25;
            }
        }

        .slick-dots {
            position: relative;
            top: 32px;

            li {
                width: 12px;
                height: 12px;
                margin: 0 8px;
            }

            button {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #C4C4C4;
            }

            .slick-active button {
                background: #0D7DFC;
            }
        }
    }
}
