.message-tabs {
  max-height: calc(100vh - 100px);
  min-height: 600px;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px;
  position: relative;

  .read-all {
    position: absolute;
    top: 32px;
    right: 20px;
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }
  }
  .empty {
    padding: 50px;
    text-align: center;
  }

  .ant-tabs-top-bar {
    margin-bottom: 0;
  }

  .list-wrapper {
    margin-bottom: 12px;
  }

  .ant-pagination {
    float: right;
  }

  .msg-info-item {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 14px 0 9px 0;
    height: 44px;
    border-bottom: 1px solid #f1f1f1;
    color: #717171;

    .user-icon {
      height: 20px;
      width: 20px;
      flex: none;
    }

    .user-name {
      margin: 0 30px 0 25px;
    }

    .msg-wrapper {
      display: flex;
      cursor: pointer;
      min-width: 0;
    }

    .msg-title {
      white-space: nowrap;
    }

    .msg-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 20px;
      color: #25aefc;
    }

    &[data-is-read='true'] {
      color: #bfbfbf;
      .msg-content {
        color: #a6ddfd;
      }
    }

    &[data-with-site='false'] {
      .msg-wrapper {
        cursor: default;
      }
    }
  }
}
