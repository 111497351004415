.quiz-page {
    .status-success {
        color: var(--color-success);
        &::before {
            content: '';
            width: 6px;
            height: 6px;
            background: var(--color-success);
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
    .status-warning {
        color: var(--color-warning);
        &::before {
            content: '';
            width: 10px;
            height: 10px;
            background: var(--color-warning);
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}