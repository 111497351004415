.ui-user-comment-list {
  .comment-block {
    border-bottom: 1px solid #e1e4e8;
    .comment-header {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      color: #24292e;
      align-items: baseline;
      a {
        color: #24292e;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          color: #0366d6;
        }
      }
      .space {
        flex: 1;
      }
      > div {
        margin: 0 5px;
        &.time {
          font-size: 12px;
          color: #6a737d;
        }
        &.delete {
          color: #6a737d;
          font-size: 12px;
          cursor: pointer;
        }
      }
      img {
        width: 15px;
        margin-right: 5px;
      }
    }
    .comment-body {
      margin-top: 16px;
      margin-bottom: 16px;
      border: 1px solid #d1d5da;
      border-radius: 3px;
      background: #fff;
      padding: 16px;
      .editor-fold-wrapper {
        .editor-wrapper.preview {
          max-width: unset;
          &[data-is-fold='true'] {
            margin-bottom: 30px;
          }
        }
        .fold-button {
          left: 45%;
        }
      }
    }
  }
  .load-more {
    width: 100%;
    padding: 6px;
    margin-top: 20px;
    font-weight: 600;
    color: #0366d6;
    background: #fff;
    border: 1px solid #e1e4e8;
    border-radius: 3px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
  }
}
